import { isEqual } from 'lodash';
import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
} from '../location.types';

import { encoders } from './serializers';
import { DEFAULT_SEARCH_REQUEST } from './defaultSearchRequest';
import { SearchDocumentType } from '@wix/client-search-sdk';

const isDocumentTypeValid = (doc: SearchDocumentType) =>
  Object.values(SearchDocumentType).includes(doc);

export function encodeSearchRequest({
  query,
  documentType,
  page,
  sortBy,
  productMinPrice,
  productMaxPrice,
  productCollections,
}: ILocationSearchRequest): IEncodedLocationSearchRequest {
  return {
    ...(query !== '' && { q: encoders.encodeQuery(query) }),
    ...(documentType &&
      isDocumentTypeValid(documentType) && {
        qc: encoders.encodeDocumentType(documentType),
      }),
    ...(page &&
      page !== DEFAULT_SEARCH_REQUEST.page && {
        page: encoders.encodePageNumber(page),
      }),
    ...(sortBy &&
      !isEqual(sortBy, DEFAULT_SEARCH_REQUEST.sortBy) && {
        sort: encoders.encodeSortBy(sortBy),
      }),
    ...(productMinPrice !== undefined && {
      pmin: encoders.encodeProductPrice(productMinPrice),
    }),
    ...(productMaxPrice !== undefined && {
      pmax: encoders.encodeProductPrice(productMaxPrice),
    }),
    ...(productCollections !== undefined &&
      !!productCollections.length && {
        pc: encoders.encodeProductCollections(productCollections),
      }),
  };
}
