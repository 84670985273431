import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Divider } from 'wix-ui-tpa/Divider';

import { IWidgetProps } from '../Widget/Widget.types';
import { ProductFacetCollections } from './ProductFacetCollections/ProductFacetCollections';

import { classes } from './ProductFacets.st.css';

interface ProductFacetsProps {
  facets: IWidgetProps['productFacets'];
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
}

export const ProductFacets: FC<ProductFacetsProps> = ({
  facets,
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();

  const { collections, filter } = facets;

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{t('searchResults.facets.title')}</span>
      </div>
      <Divider className={classes.divider} />
      <ProductFacetCollections
        collections={collections}
        filterCollections={filter.collections}
        onFacetsFilterChange={onFacetsFilterChange}
      />
    </div>
  );
};
