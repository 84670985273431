import {
  parseMediaSrc,
  types,
  MediaObject,
  ImageMediaObject,
} from '@wix/santa-platform-utils/dist/esm/mediaSrcHandler/mediaSrcHandler';
import { getScaleToFillImageURL } from '@wix/image-client-api/dist/imageClientSDK';
import { IDocumentImage } from '@wix/client-search-sdk';

function isMediaManagerImageSrc(
  mediaSrc: MediaObject,
): mediaSrc is ImageMediaObject {
  return (
    mediaSrc.type === types.IMAGE &&
    'width' in mediaSrc &&
    typeof mediaSrc.width === 'number'
  );
}

export function getWixImageUrl(
  image: IDocumentImage,
  targetWidth: number,
  targetHeight: number,
): string | undefined {
  if (!image) {
    return;
  }

  if ('src' in image) {
    const parsedMediaSrc = parseMediaSrc(image.src, types.IMAGE);

    if ('error' in parsedMediaSrc) {
      return;
    }

    if (isMediaManagerImageSrc(parsedMediaSrc)) {
      return getWixImageUrl(
        {
          name: parsedMediaSrc.mediaId,
          width: parsedMediaSrc.width,
          height: parsedMediaSrc.height,
        },
        targetWidth,
        targetHeight,
      );
    }

    // TODO: fix 'any' type below
    return (parsedMediaSrc as any).mediaId;
  }

  if (!image.name) {
    return;
  }

  if (image.width && image.height) {
    targetHeight = (targetWidth / image.width) * image.height;
  }

  return getScaleToFillImageURL(
    image.name,
    image.width,
    image.height,
    targetWidth,
    targetHeight,
  );
}
