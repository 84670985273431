import * as React from 'react';

import { Tabs, ALIGNMENT, TabsProps } from 'wix-ui-tpa/Tabs';

import { ITab } from '../../types/types';
import { useTranslation } from '@wix/yoshi-flow-editor';

type TabLineProps = {
  activeTabIndex?: number;
  alignment?: ALIGNMENT;
  items: ITab[];
  onTabClick: TabsProps['onTabClick'];
};

export const TabLine: React.FC<TabLineProps> = ({
  activeTabIndex,
  alignment = ALIGNMENT.left,
  items,
  onTabClick,
}) => {
  const { t } = useTranslation();
  return (
    <Tabs
      activeTabIndex={activeTabIndex}
      alignment={alignment}
      aria-label={t('searchResults.tabs.menu.accessibilityLabel')}
      data-hook="tabs"
      items={items}
      onTabClick={onTabClick}
    />
  );
};
