import {
  ISearchRequest,
  ISearchRequestFilter,
  ISearchResponse,
} from '@wix/client-search-sdk';

export interface IProductFacetsFilter {
  minPrice?: number;
  maxPrice?: number;
  collections?: string[];
}

export interface IProductFacetsCollection {
  value: string;
  count: number;
}

export interface IProductFacetsState {
  enabled: boolean;
  filter: IProductFacetsFilter;
  minPrice: number;
  maxPrice: number;
  collections: IProductFacetsCollection[];
}

const MAX_COLLECTIONS = 10;
const FACET_COLLECTIONS = 'collections';
const FACET_PRICE = 'discountedPriceNumeric';

export const convertProductFacetsFilterToRequestParams = ({
  minPrice,
  maxPrice,
  collections = [],
}: IProductFacetsFilter): Partial<ISearchRequest> => {
  const hasMinPrice = minPrice !== undefined;
  const hasMaxPrice = maxPrice !== undefined;

  const priceFilter =
    hasMinPrice || hasMaxPrice
      ? {
          discountedPriceNumeric: {
            ...(hasMinPrice && { $gte: minPrice }),
            ...(hasMaxPrice && { $lte: maxPrice }),
          },
        }
      : undefined;

  const collectionsFilter = collections.length
    ? {
        collections: {
          $any: collections,
        },
      }
    : undefined;

  const filter =
    priceFilter || collectionsFilter
      ? {
          $and: [priceFilter, collectionsFilter].filter(
            (value) => !!value,
          ) as ISearchRequestFilter[],
        }
      : undefined;

  return {
    facets: {
      clausesV2: [
        {
          term: {
            name: FACET_COLLECTIONS,
            limit: MAX_COLLECTIONS,
          },
        },
        {
          aggregation: {
            name: FACET_PRICE,
            aggregation: 'MIN_MAX',
          },
        },
      ],
    },
    filter,
  };
};

export const convertProductFacetsRequestParamsToFilter = ({
  filter,
}: Partial<ISearchRequest>): IProductFacetsFilter => {
  if (!filter || !filter.$and) {
    return {};
  }

  const priceFilter = filter.$and.find(
    ({ discountedPriceNumeric }) => !!discountedPriceNumeric,
  )?.discountedPriceNumeric;

  const [minPrice, maxPrice] =
    priceFilter && !Array.isArray(priceFilter)
      ? [priceFilter.$gte, priceFilter.$lte]
      : [];

  const collectionsFilter = filter.$and.find(({ collections }) => !!collections)
    ?.collections;

  const collections = !Array.isArray(collectionsFilter)
    ? collectionsFilter?.$any ?? []
    : [];

  return {
    minPrice,
    maxPrice,
    collections,
  };
};

export const extractProductFacetsFromSearchResponse = ({
  facets,
}: ISearchResponse): Pick<
  IProductFacetsState,
  'minPrice' | 'maxPrice' | 'collections'
> => {
  const collections = (
    facets.find(({ terms }) => !!terms && terms.facet === FACET_COLLECTIONS)
      ?.terms?.facets ?? []
  ).map(({ facetValue, count }) => ({ value: facetValue, count }));

  const minPrice =
    facets.find(
      ({ minAggregation }) =>
        !!minAggregation && minAggregation.facet === FACET_PRICE,
    )?.minAggregation?.minValue ?? 0;

  const maxPrice =
    facets.find(
      ({ maxAggregation }) =>
        !!maxAggregation && maxAggregation.facet === FACET_PRICE,
    )?.maxAggregation?.maxValue ?? 0;

  return {
    minPrice,
    maxPrice,
    collections,
  };
};
