import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';
import { Alignment } from '@wix/search-results-settings-definitions';

export type ISettingsParams = {
  isSearchBarEnabled: SettingsParamType.Boolean;
  searchBarPlaceholder: SettingsParamType.Text;
  itemsPerPage: SettingsParamType.Number;
  isResultsMessageWithQuery: SettingsParamType.Boolean;
  isResultsMessageWithNumber: SettingsParamType.Boolean;
  isResultsEmptyMessageWithNumber: SettingsParamType.Boolean;
  isResultsEmptyMessageWithQuery: SettingsParamType.Boolean;
  resultsMenuAlignment: Alignment;
  paginationAlignment: Alignment;
  isProductsAddToCartEnabled: SettingsParamType.Boolean;
  productsAddToCartButtonText: SettingsParamType.String;
  isProductsFacetsEnabled: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  isSearchBarEnabled: {
    key: 'isSearchBarEnabled',
    getDefaultValue() {
      return true;
    },
  },
  searchBarPlaceholder: {
    key: 'searchBarPlaceholder',
    getDefaultValue({ t }) {
      return t('settings.searchBar.placeholderInput.defaultValue');
    },
  },
  itemsPerPage: {
    key: 'itemsPerPage',
    getDefaultValue() {
      return 10;
    },
  },
  isResultsMessageWithQuery: {
    key: 'isResultsMessageWithQuery',
    getDefaultValue() {
      return false;
    },
  },
  isResultsMessageWithNumber: {
    key: 'isResultsMessageWithNumber',
    getDefaultValue() {
      return true;
    },
  },
  isResultsEmptyMessageWithNumber: {
    key: 'appLayout',
    getDefaultValue() {
      return false;
    },
  },
  isResultsEmptyMessageWithQuery: {
    key: 'isResultsEmptyMessageWithQuery',
    getDefaultValue() {
      return false;
    },
  },
  resultsMenuAlignment: {
    key: 'resultsMenuAlignment',
    getDefaultValue() {
      return Alignment.Left;
    },
  },
  paginationAlignment: {
    key: 'paginationAlignment',
    getDefaultValue() {
      return Alignment.Center;
    },
  },
  isProductsAddToCartEnabled: {
    key: 'isProductsAddToCartEnabled',
    getDefaultValue() {
      return true;
    },
  },
  productsAddToCartButtonText: {
    key: 'productsAddToCartButtonText',
    getDefaultValue({ t }) {
      return t('settings.display.products.addToCart.buttonText');
    },
  },
  isProductsFacetsEnabled: {
    key: 'isProductsFacetsEnabled',
    getDefaultValue() {
      return true;
    },
  },
});
