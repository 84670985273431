import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CheckboxGroup } from 'wix-ui-tpa/CheckboxGroup';
import { Checkbox } from 'wix-ui-tpa/Checkbox';

import { IWidgetProps } from '../../Widget/Widget.types';

import { classes } from './ProductFacetCollections.st.css';

interface ProductFacetCollectionsProps {
  collections: IWidgetProps['productFacets']['collections'];
  filterCollections?: IWidgetProps['productFacets']['filter']['collections'];
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
}

export const ProductFacetCollections: FC<ProductFacetCollectionsProps> = ({
  collections,
  filterCollections = [],
  onFacetsFilterChange,
}) => {
  const { t } = useTranslation();
  if (!collections.length) {
    return null;
  }

  const renderLabel = (title: string, count: number) => {
    return (
      <span className={classes.label}>
        {title} <span className={classes.count}>({count})</span>
      </span>
    );
  };

  return (
    <>
      <div className={classes.subtitle}>
        {t('searchResults.facets.collectionTitle')}
      </div>
      <CheckboxGroup>
        {collections.map(({ value, count }) => (
          <Checkbox
            withFocusRing
            label={renderLabel(value, count)}
            checked={filterCollections.includes(value)}
            key={value}
            onChange={({ checked }) => {
              onFacetsFilterChange({
                collections: checked
                  ? [...filterCollections, value]
                  : [...filterCollections.filter((v) => v !== value)],
              });
            }}
          />
        ))}
      </CheckboxGroup>
    </>
  );
};
