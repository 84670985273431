import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';

import { ISearchLocation } from './location.types';
import { decodePath, encodePath } from './path';
import {
  convertProductFacetsFilterToRequestParams,
  convertProductFacetsRequestParamsToFilter,
} from '../../components/SearchResults/Widget/platform/products/facets';
import { DEFAULT_SEARCH_REQUEST } from './params/defaultSearchRequest';
import appConfig from '../../../.application.json';

const SEARCH_RESULTS_PAGE_ID = 'search_results';

export const toLocationSearchRequest: ISearchLocation['toLocationSearchRequest'] = (
  searchRequest,
) => {
  const { documentType, query, paging } = searchRequest;
  const {
    minPrice: productMinPrice,
    maxPrice: productMaxPrice,
    collections: productCollections,
  } = convertProductFacetsRequestParamsToFilter(searchRequest);

  return {
    documentType,
    query,
    page: paging.page,
    productMinPrice,
    productMaxPrice,
    productCollections,
  };
};

export const buildSearchResultsUrl: ISearchLocation['buildSearchResultsUrl'] = (
  searchResultsAbsoluteUrl,
  locationSearchRequest,
) => {
  return `${searchResultsAbsoluteUrl}/${encodePath(locationSearchRequest)}`;
};

export const createSearchLocation = (wixCodeApi: IWixAPI): ISearchLocation => {
  function getSectionUrl() {
    return wixCodeApi.site.getSectionUrl({
      appDefinitionId: appConfig.appDefinitionId,
      sectionId: SEARCH_RESULTS_PAGE_ID,
    });
  }

  return {
    decodePath,
    encodePath,

    getSearchResultsRelativeUrl: async () => {
      const { relativeUrl } = await getSectionUrl();
      if (typeof relativeUrl !== 'string') {
        throw new Error(
          'Unexpected value returned from wixCodeApi.site.getSectionUrl().',
        );
      }
      return relativeUrl;
    },

    getSearchResultsAbsoluteUrl: async () => {
      const { url } = await getSectionUrl();
      if (typeof url !== 'string') {
        throw new Error(
          'Unexpected value returned from wixCodeApi.site.getSectionUrl().',
        );
      }
      return url;
    },

    navigateToSearchResults: async (searchRequest) => {
      const { relativeUrl } = await getSectionUrl();
      wixCodeApi.location.to?.(`${relativeUrl}/${encodePath(searchRequest)}`);
    },

    toLocationSearchRequest,

    toSDKSearchRequest: (
      {
        page,
        documentType,
        query,
        productMinPrice,
        productMaxPrice,
        productCollections,
      },
      pageSize,
      showProductFacets,
    ) => {
      return {
        documentType,
        query,
        paging: {
          page: page || DEFAULT_SEARCH_REQUEST.page,
          pageSize,
        },
        ...(showProductFacets &&
          convertProductFacetsFilterToRequestParams({
            minPrice: productMinPrice,
            maxPrice: productMaxPrice,
            collections: productCollections,
          })),
      };
    },

    buildSearchResultsUrl,
  };
};
