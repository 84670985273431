export function encodeProductPrice(price: number): string {
  return price.toString();
}

export function decodeProductPrice(urlPrice?: string): number | undefined {
  if (typeof urlPrice !== 'string') {
    return;
  }

  const price = parseFloat(urlPrice);
  return !isNaN(price) ? price : undefined;
}
