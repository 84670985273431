import { ILocationSearchRequest } from '../location.types';
import { SearchDocumentType } from '@wix/client-search-sdk';

export const DEFAULT_SEARCH_REQUEST: Required<ILocationSearchRequest> = {
  documentType: SearchDocumentType.All,
  query: '',
  page: 1,
  sortBy: [],
  productMinPrice: -Infinity,
  productMaxPrice: +Infinity,
  productCollections: [],
};
