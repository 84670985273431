import decodeUriComponent from 'decode-uri-component';
import { DEFAULT_SEARCH_REQUEST } from '../defaultSearchRequest';

const COLLECTIONS_DELIMITER = '~';

export function encodeProductCollections(collections: string[]): string {
  return encodeURIComponent(collections.join(COLLECTIONS_DELIMITER));
}

export function decodeProductCollections(urlCollections?: string): string[] {
  if (!urlCollections) {
    return DEFAULT_SEARCH_REQUEST.productCollections;
  }

  return decodeUriComponent(urlCollections).split(COLLECTIONS_DELIMITER);
}
