import * as React from 'react';
import {
  withEnvironment,
  InjectedEnvironmentProps,
} from '@wix/yoshi-flow-editor';
import { getWixImageUrl } from '../getWixImageUrl';
import { Thumbnail } from '../Thumbnail';
import { Title } from '../Title';
import { Description } from '../Description';
import { IListLayoutItemProps } from '../Layout.types';

import { st, classes } from './ListLayoutItem.st.css';

export const LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH = 176;
const THUMBNAIL_HEIGHT = 124;

interface ListLayoutItemProps
  extends IListLayoutItemProps,
    InjectedEnvironmentProps {}

class ListLayoutItemComp extends React.Component<ListLayoutItemProps> {
  static defaultProps: Partial<IListLayoutItemProps> = {
    renderContent: (props: IListLayoutItemProps) => {
      const { renderDescription, renderTitle } = props;
      return (
        <div className={classes.content}>
          {renderTitle ? renderTitle(props) : null}
          {renderDescription ? renderDescription(props) : null}
        </div>
      );
    },
    renderTitle: ({ item, onLinkClick }: IListLayoutItemProps) => {
      return <Title title={item.title} onClick={onLinkClick} url={item.url} />;
    },
    renderDescription: ({ item }: IListLayoutItemProps) => {
      return <Description description={item.description} />;
    },
  };

  render() {
    const {
      isWideThumbnail,
      item,
      onClick,
      onLinkClick,
      renderContent,
      thumbnailHeight = THUMBNAIL_HEIGHT,
      thumbnailWidth = LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH,
      environment,
    } = this.props;

    const { isMobile, isEditorX } = environment;

    let imageUrl: string;

    if (
      item.image &&
      'name' in item.image &&
      item.image.name.startsWith('http')
    ) {
      imageUrl = item.image.name;
    } else {
      imageUrl =
        getWixImageUrl(item.image, thumbnailWidth, thumbnailHeight) || '';
    }

    return (
      <li
        data-hook="list-layout-item"
        className={st(classes.root, {
          fixed: !isEditorX,
          fluid: isEditorX,
          mobileView: isMobile,
        })}
        onClick={onClick}
      >
        {!isMobile && (
          <Thumbnail
            imageUrl={imageUrl}
            isWide={isWideThumbnail}
            onClick={onLinkClick}
            title={item.title}
            url={item.url}
          />
        )}
        {renderContent ? renderContent(this.props) : null}
      </li>
    );
  }
}

export const ListLayoutItem = withEnvironment(ListLayoutItemComp);
